import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragHandle from './DragHandle';

const DndWithHandle = ({ id, children, className }) => {
  const { setNodeRef, attributes, listeners, transition, transform, isDragging } = useSortable({
    id: id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1,
  };

  return (
    <div ref={setNodeRef} style={style} className={`dnd-wrapper ${className}`}>
      <DragHandle {...listeners} {...attributes} />
      {children}
    </div>
  );
};

export default DndWithHandle;

DndWithHandle.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
};
