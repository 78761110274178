import React, { useState, useEffect } from 'react';
import { Divider } from 'antd';
import { useIntercom } from 'react-use-intercom';
import { useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';
import WebFont from 'webfontloader';
import * as _ from 'lodash';

import {
  fontListEnglish,
  fontListHebrew,
  fontSizes,
  defaultHebrewBodySettings,
  defaultBodySettings,
} from 'pages/Proposal/constants/constants';
import { fontLoadedVar } from 'graphql/cache';

import CustomSelect from 'components/CustomSelect';

const cleanString = (s) => s.replace(/^["']|["']$/g, '');

const FontTools = ({ bodyFont, language, editorState, onToggleFontInlineStyle, user }) => {
  const fontLoaded = useReactiveVar(fontLoadedVar);
  const { trackEvent } = useIntercom();
  const selection = editorState.getSelection();
  const block = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());
  const currentStyle = block ? editorState?.getCurrentInlineStyle() : '';

  const [fontFamilies] = useState(() => {
    const hebrewOrEnglish = language?.toLowerCase() === 'hebrew' ? fontListHebrew : fontListEnglish;
    return (user?.uploadedFonts || []).concat(hebrewOrEnglish);
  });

  const [selectedFontFamily, setSelectedFontFamily] = useState(
    fontFamilies.find((font) => currentStyle && currentStyle.has(font.family)) ||
      fontFamilies[bodyFont?.family] ||
      (language?.toLowerCase() === 'hebrew' ? defaultHebrewBodySettings : defaultBodySettings)
  );

  const [selectedWeight, setSelectedWeight] = useState(
    selectedFontFamily?.weights.find((weight) => currentStyle && currentStyle.has(`${weight}`)) ||
      bodyFont?.weight ||
      selectedFontFamily.weight ||
      400
  );

  const [selectedSize, setSelectedSize] = useState(
    fontSizes.find((size) => currentStyle && currentStyle.has(`${size}`)) ||
      bodyFont?.fontSize ||
      selectedFontFamily.fontSize ||
      '20px'
  );

  useEffect(() => {
    const activeEditor = document.querySelector('.rich-editor.active');
    const parentStyle = window.getComputedStyle(activeEditor.parentElement);
    const fontFamily = cleanString(parentStyle.getPropertyValue('font-family'));
    const fontSize = parentStyle.getPropertyValue('font-size');
    if (activeEditor && activeEditor.parentElement) {
      const matchedFontFamily =
        fontFamilies.find((font) => currentStyle && currentStyle.has(font.family)) ||
        fontFamilies.find((font) => cleanString(font.family) === fontFamily) ||
        fontFamilies[bodyFont?.family] ||
        (language?.toLowerCase() === 'hebrew' ? defaultHebrewBodySettings : defaultBodySettings);

      if (selectedFontFamily !== matchedFontFamily) {
        setSelectedFontFamily(matchedFontFamily);
      }
    }
    if (
      selectedWeight !==
        selectedFontFamily?.weights.find(
          (weight) => currentStyle && currentStyle.has(`${weight}`)
        ) ||
      bodyFont?.weight ||
      selectedFontFamily.weight ||
      400
    ) {
      setSelectedWeight(
        selectedFontFamily?.weights.find(
          (weight) => currentStyle && currentStyle.has(`${weight}`)
        ) ||
          bodyFont?.weight ||
          selectedFontFamily.weight ||
          400
      );
    }

    const matchedFontSize =
      fontSizes.find((size) => currentStyle && currentStyle.has(`${size}`)) ||
      `${fontSize}` ||
      bodyFont?.fontSize ||
      selectedFontFamily.fontSize ||
      '20px';

    if (selectedSize !== matchedFontSize) {
      setSelectedSize(matchedFontSize);
    }
  }, [
    bodyFont,
    currentStyle,
    fontFamilies,
    language,
    selectedFontFamily,
    selectedSize,
    selectedWeight,
  ]);

  const handleChange = (value, type) => {
    if (type === 'family') {
      const selectedFont = fontFamilies.find((font) => font.family === value);
      setSelectedFontFamily(selectedFont);
    } else if (type === 'weight') {
      setSelectedWeight(value);
    } else if (type === 'size') {
      setSelectedSize(value);
    }

    trackEvent('fonts-updated');

    onToggleFontInlineStyle({ inlineStyle: value, type, fontFamilies });
  };

  useEffect(() => {
    if (!fontLoaded) {
      fontLoadedVar(true);

      const fontsDefault = [];
      fontFamilies.forEach((f) => {
        f.weights.forEach((w) => {
          fontsDefault.push({
            family: `${f.family}:${w}${
              language?.toLowerCase() === 'hebrew' && f.family !== 'Arial' ? ':hebrew' : ''
            }`,
            source: f.source,
          });
        });
      });

      WebFont.load({
        google: {
          families: _.compact(fontsDefault.map((f) => f.source === 'Google' && f.family)),
        },
        custom: {
          families: ['Open Sans Hebrew'],
        },
      });
    }
  }, [fontFamilies, fontLoaded, language]);

  return (
    <>
      <CustomSelect
        list={fontFamilies.map((fontFamily) => {
          return {
            label: fontFamily.family,
            value: fontFamily.family,
            style: { fontFamily: fontFamily.family },
          };
        })}
        className="rich-editor-toolbar-font-family"
        selected={selectedFontFamily?.family || bodyFont.family}
        onChange={(value) => handleChange(value, 'family')}
      />

      <CustomSelect
        list={selectedFontFamily?.weights.map((fontWeight) => {
          return { label: fontWeight, value: fontWeight };
        })}
        selected={selectedWeight}
        onChange={(value) => handleChange(value, 'weight')}
      />

      <CustomSelect
        list={fontSizes.map((fontSize) => {
          return { label: fontSize, value: fontSize };
        })}
        selected={selectedSize}
        onChange={(value) => handleChange(value, 'size')}
      />

      <Divider type="vertical" />
    </>
  );
};

FontTools.defaultProps = {
  bodyFont: '',
};

FontTools.propTypes = {
  language: PropTypes.string.isRequired,
  bodyFont: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  editorState: PropTypes.instanceOf(Object).isRequired,
  onToggleFontInlineStyle: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default FontTools;
