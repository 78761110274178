import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Divider } from 'antd';

import { BackArrowIcon, TickIcon } from 'components/Icons';
import { colors } from 'constants/tags_color';
import Loader from 'components/Loader';

const CreateTags = ({ isDuplicate, createTag, setCreateTag, createTagHandle, tagsLoader }) => {
  const [error, setError] = useState(false);
  const [tagName, setTagName] = useState(createTag || '');
  const [color, setColor] = useState(false);
  const [tagType, setTagType] = useState('');
  const inputRef = useRef(null);

  const validationError = !tagName.trim() || !tagType;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const saveNewTag = () => {
    const _tagName = tagName.trim();
    if (!tagName || !tagType || _tagName === '') {
      setError(`* Please ${_tagName.length === 0 ? 'enter name and' : ''} select color of tag.`);
      return;
    }

    if (isDuplicate(_tagName, color, tagType)) {
      setError('An identical tag already exists.');
      return;
    }

    createTagHandle(_tagName, color, tagType);
  };

  return (
    <div className="tags-card" onClick={(e) => e.stopPropagation()}>
      {tagsLoader && <Loader />}

      <div className="tag-header">
        <div className="tag-title">Create New Tag</div>
        <div className="tags-card-right-menu">
          <Button
            type="primary"
            size="small"
            className="small-btn white-btn"
            onClick={() => setCreateTag(false)}
            icon={<BackArrowIcon className="back-tag-icon" />}>
            Back
          </Button>
        </div>
      </div>
      <div className="create-tags-input">
        <Input
          placeholder="create new tags"
          maxLength={25}
          ref={inputRef}
          value={tagName}
          onChange={(e) => {
            setTagName(e.target.value.substring(0, 25));
            if (error && e.target.value.trim().length > 0 && color) {
              setError(false);
            }
          }}
          onPressEnter={() => {
            inputRef.current.blur();
            saveNewTag();
          }}
        />
      </div>

      <div className="tags-body-wrapper">
        <div className="select-tag-color">Select Color</div>
        <div className="create-tag-color-list">
          {colors.map((tagColor) => {
            return (
              <div
                key={tagColor.color}
                className={`color-circle ${color === tagColor.color ? 'selected' : ''}`}
                style={{
                  border: `2px solid ${tagColor.color}`,
                  ...(tagColor.type === 'fill' && { background: tagColor.color }),
                }}
                onClick={() => {
                  setTagType(tagColor.type);
                  setColor(tagColor.color);
                  if (error && tagName.trim().length > 0) {
                    setError(false);
                  }
                }}>
                {color === tagColor.color && (
                  <div className="check-mark">
                    <TickIcon />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <Divider className="tags-divider" />
      <div className="save-tag-footer-section">
        {error && <p className="tags-error">{error}</p>}

        <Button
          type="primary"
          size="middle"
          className="medium-btn secondary-btn save-tag-button"
          onClick={saveNewTag}
          disabled={error || validationError}>
          Save New Tag
        </Button>
      </div>
    </div>
  );
};

CreateTags.propTypes = {
  createTag: PropTypes.any,
  setCreateTag: PropTypes.func,
  createTagHandle: PropTypes.func,
};

export default CreateTags;
