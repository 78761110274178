import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7083 11.5833L13.5417 10.4375C14.1219 10.2806 14.5934 9.97748 14.956 9.52815C15.3187 9.07883 15.5 8.56944 15.5 8C15.5 7.30556 15.2569 6.71528 14.7708 6.22917C14.2847 5.74306 13.6944 5.5 13 5.5H10V4H13C14.1067 4 15.05 4.39044 15.83 5.17133C16.61 5.95221 17 6.89661 17 8.00454C17 8.7654 16.7951 9.46528 16.3854 10.1042C15.9757 10.7431 15.4167 11.2361 14.7083 11.5833ZM11.875 8.75L10.375 7.25H12V8.75H11.875ZM15 16.125L0.875 2L1.9375 0.9375L16.0625 15.0625L15 16.125ZM8 12H5C3.89333 12 2.95 11.61 2.17 10.83C1.39 10.05 1 9.10667 1 8C1 7.11206 1.26389 6.32063 1.79167 5.62571C2.31944 4.93079 3.06944 4.38194 4.04167 3.97917L5.5 5.5H5.02083C4.3125 5.5 3.71528 5.74306 3.22917 6.22917C2.74306 6.71528 2.5 7.30556 2.5 8C2.5 8.69444 2.74306 9.28472 3.22917 9.77083C3.71528 10.2569 4.30556 10.5 5 10.5H8V12ZM6 8.75V7.25H7.16667L8.64583 8.75H6Z"
      fill="#05034D"
    />
  </svg>
);

const UnLinkIcon = (props) => <Icon component={svg} {...props} />;

export default UnLinkIcon;
