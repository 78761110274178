import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 12V10.2292L11.4167 5.8125L13.1875 7.58333L8.77083 12H7ZM0 7.75V6.25H6V7.75H0ZM13.7708 7L12 5.22917L12.6667 4.5625C12.8194 4.40972 12.9965 4.33333 13.1979 4.33333C13.3993 4.33333 13.5764 4.40972 13.7292 4.5625L14.4375 5.27083C14.5903 5.42361 14.6667 5.60069 14.6667 5.80208C14.6667 6.00347 14.5903 6.18056 14.4375 6.33333L13.7708 7ZM0 4.625V3.125H9V4.625H0ZM0 1.5V0H9V1.5H0Z"
      fill="#5D5AB5"
    />
  </svg>
);

const InputTextIcon = (props) => <Icon component={svg} {...props} />;

export default InputTextIcon;
