import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Image, Row, Col, Button } from 'antd';

import CloseIcon from 'components/Icons/CloseIcon';
import PublishContent from 'pages/Proposal/components/Publish/components/PublishContent';
import PublishHeaderSection from 'pages/Proposal/components/Publish/components/PublishContent/components/PublishHeaderSection/PublishHeaderSection';
import helpers from 'helpers/proposal';
import { PublishSection } from 'pages/Proposal/components/Publish/components/PublishContent/components';

import './PreviewProposalModal.scss';

const PreviewProposalModal = ({
  onCancel,
  section,
  template,
  coverMedia,
  config,
  loadConfig,
  title,
  user,
  type,
  onClickMenuItem,
  isLocked,
}) => {
  const templateId = template?._id;
  React.useEffect(() => {
    if (loadConfig) {
      loadConfig({
        variables: {
          language: template?.language,
        },
      });
    }
  }, [config, loadConfig, template?.language]);

  const renderCoverMediaPreview = () => {
    if (type === 'application/pdf' || type === 'pdf') {
      return <iframe className="preview-media-modal" src={coverMedia} title={title} />;
    } else if (
      [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ].includes(type)
    ) {
      return (
        <iframe
          className="preview-media-modal"
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${coverMedia}`}
          title={title}
        />
      );
    } else if (
      [
        'attachmentapplication/msword',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ].includes(type)
    ) {
      return (
        <iframe
          className="preview-media-modal"
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${coverMedia}`}
          title={title}
        />
      );
    }
    return <Image preview={{ visible: false }} className="preview-cover-modal" src={coverMedia} />;
  };

  return (
    <Modal
      className="preview-proposal-modal"
      visible
      okText="CONTINUE"
      centered
      cancelText="CANCEL"
      closeIcon={<CloseIcon className="close-icon" />}
      footer={null}
      onCancel={onCancel}
      width={1000}>
      <Row gutter={[12, 12]}>
        <Col md={12} lg={12} xl={12}>
          <h3 className={`title ${section?.language === 'hebrew' ? 'hebrew' : ''}`}>{title}</h3>
        </Col>
        <Col md={12} lg={12} xl={12}>
          {template && (
            <Button
              className="proposal-button-right"
              onClick={() => onClickMenuItem({ actionType: 'use_preview_as_template', templateId })}
              disabled={isLocked}>
              Use as Proposal
            </Button>
          )}
        </Col>
      </Row>

      {template && <PublishContent config={config} prop={template} user={user} previewTemplate />}
      {section && (
        <div
          className={`publish-content preview-section ${
            section ? `language-${section?.language?.toLowerCase() || 'english'}` : ''
          }`}>
          {section.isHeader ? (
            <PublishHeaderSection
              proposal={{ draft: { section } }}
              name="section"
              configText={(x, disableTrim) => {
                return helpers.configText({
                  x,
                  notmarked: true,
                  config,
                  prop: { draft: { section } },
                  user,
                  vars: {},
                  disableTrim,
                });
              }}
              isPreview={true}
              ispdf={false}
            />
          ) : (
            <PublishSection
              prop={{ draft: { section } }}
              name="section"
              configText={(x, disableTrim) => {
                return helpers.configText({
                  x,
                  notmarked: true,
                  config,
                  prop: { draft: { section } },
                  user,
                  vars: {},
                  disableTrim,
                });
              }}
              ispdf={false}
            />
          )}
        </div>
      )}
      {coverMedia && renderCoverMediaPreview()}
    </Modal>
  );
};

PreviewProposalModal.defaultProps = {
  config: null,
  type: '',
  isLocked: false,
  onClickMenuItem: () => {},
};

PreviewProposalModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  template: PropTypes.shape({}),
  section: PropTypes.shape({}),
  coverMedia: PropTypes.string,
  config: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClickMenuItem: PropTypes.func,
  isLocked: PropTypes.bool,
};

export default PreviewProposalModal;
