/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99986 3.42188C5.30019 3.42188 3.35348 5.30252 2.37972 6.5291C2.15272 6.81503 2.15272 7.19278 2.37972 7.47871C3.35348 8.70529 5.30019 10.5859 7.99986 10.5859C10.6995 10.5859 12.6462 8.70529 13.62 7.47871C13.847 7.19278 13.847 6.81503 13.62 6.5291C12.6462 5.30252 10.6995 3.42188 7.99986 3.42188ZM1.20492 5.59645C2.25431 4.27461 4.58815 1.92188 7.99986 1.92188C11.4116 1.92188 13.7454 4.27461 14.7948 5.59645C15.4554 6.42855 15.4554 7.57926 14.7948 8.41136C13.7454 9.7332 11.4116 12.0859 7.99986 12.0859C4.58815 12.0859 2.25431 9.7332 1.20492 8.41136C0.544324 7.57926 0.544324 6.42855 1.20492 5.59645Z"
      fill="#05034D"
    />
    <path
      d="M7.9999 5.77031C7.23612 5.77031 6.67205 6.34979 6.67205 6.99844C6.67205 7.64708 7.23612 8.22656 7.9999 8.22656C8.76369 8.22656 9.32776 7.64708 9.32776 6.99844C9.32776 6.34979 8.76369 5.77031 7.9999 5.77031ZM5.47205 6.99844C5.47205 5.62779 6.63423 4.57031 7.9999 4.57031C9.36557 4.57031 10.5278 5.62779 10.5278 6.99844C10.5278 8.36909 9.36557 9.42656 7.9999 9.42656C6.63423 9.42656 5.47205 8.36909 5.47205 6.99844Z"
      fill="#05034D"
    />
  </svg>
);

const EyeShowIcon = (props) => <Icon component={svg} {...props} />;

export default EyeShowIcon;
