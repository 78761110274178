/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="25" height="23" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="4" fill="#F5F6FA" />
    <path
      d="M14.9396 25.1927L18.9229 7.26557L16.9774 6.83331L12.9942 24.7604L14.9396 25.1927ZM12.5982 19.2185L11.3532 20.7742L6.37348 16.7904C6.13744 16.6012 6 16.3154 6 16.0126C6 15.7098 6.13744 15.424 6.37348 15.2348L11.3532 11.251L12.5982 12.8066L8.59046 16.0126L12.5982 19.2185ZM19.3208 12.8066L20.5657 11.251L25.5455 15.2348C25.7815 15.424 25.919 15.7098 25.919 16.0126C25.919 16.3154 25.7815 16.6012 25.5455 16.7904L20.5657 20.7742L19.3208 19.2185L23.3285 16.0126L19.3208 12.8066Z"
      fill="#AFB1BA"
    />
  </svg>
);

const ProjectDevelopmentIcon = (props) => <Icon component={svg} {...props} />;

export default ProjectDevelopmentIcon;
