import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Popover, Button } from 'antd';
import { sanitizeString } from 'utils/xss';

import TagBadge from 'components/TagBadge';
import { ADD_TAG, UPDATE_TAG, UPDATE_TAG_ORDER, DELETE_TAG } from 'graphql/mutations/tagsMutation';
import CreateTags from './CreateTags';
import ManageTags from './ManageTags';

import './ProposalItemTag.scss';

const ProposalItemTag = ({
  tags,
  tagsData,
  setTagsData,
  pid,
  auid,
  visibleTags,
  setvisibleTags,
  handleKeyCommand,
  tagWrapperWidth,
  proposalsVar,
  proposalList,
  updateProposalTags,
  setIgnoreUpdates,
  tagsFilter,
}) => {
  const [displayableTags, setDisplayableTags] = useState([]);
  const [tagsLoader, setTagsLoader] = useState(false);
  const [createTag, setCreateTag] = useState(false);
  const [editTags, setEditTags] = useState(false);

  const [selectedTagColor, setSelectedTagColor] = useState(false);
  const [openDeletePopover, setOpenDeletePopover] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchedChecked, setSearchedChecked] = useState([]);
  const [searchedUnchecked, setSearchedUnchecked] = useState([]);
  const [searchedAlltags, setSearchedAlltags] = useState([]);

  const [allCheckedTags, setAllCheckedTags] = useState([]);
  const [allUncheckedTags, setAllUncheckedTags] = useState([]);
  // store the proposal/tags data untill widget is closed
  const [updatedProposal, setUpdatedProposal] = useState(null);
  const [updatedTags, setUpdatedTags] = useState(null);

  useEffect(() => {
    // onclosing the modal
    if (!visibleTags) {
      setCreateTag(false);
      setEditTags(false);
      setOpenDeletePopover(false);

      // proposal's tag changed, update in db
      if (updatedProposal) {
        updateProposalTags(updatedProposal, [...new Set(updatedProposal.tags)]);
        setUpdatedProposal(null);
      }

      // tags order changed, update in db
      if (updatedTags?.length) {
        updateTagsOrder({
          variables: { tagIds: updatedTags },
        });

        setUpdatedTags(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleTags]);

  useEffect(() => {
    createDisplayableTags(tags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsData, tags, tagWrapperWidth]);

  useEffect(() => {
    if (!visibleTags || !tagsData.length) {
      return;
    }

    // tagsData - all tags
    getSortedCheckedUncheckedTags(tagsData, tags);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsData, tags, visibleTags]);

  const getSortedCheckedUncheckedTags = (tagsData, tags) => {
    const checkedTags = [];
    const uncheckedTags = [];

    tagsData.forEach((tag) => {
      if (tags.includes(tag._id)) {
        checkedTags.push(tag);
      } else {
        uncheckedTags.push(tag);
      }
    });

    setAllCheckedTags(checkedTags);
    setAllUncheckedTags(uncheckedTags);
  };

  const createDisplayableTags = (tags) => {
    if (!tags || !tagsData?.length || !tagWrapperWidth) {
      // if data exists then delete them
      if (displayableTags.length) {
        setDisplayableTags([]);
      }
      if (allCheckedTags.length) {
        setAllCheckedTags([]);
      }
      if (allUncheckedTags.length) {
        setAllUncheckedTags([]);
      }

      return;
    }
    if (tags.length < 2) {
      // 0 or 1 item display as it is
      setDisplayableTags(tagsData.filter((t) => tags.includes(t._id)));
    } else if (tagWrapperWidth) {
      // if tag wrapper width is calculated
      // then find how many tags can be diaplyed without going out the div
      const _displayableTags = [];
      let totalLength = 0;
      let tagsScanned = 0;

      tagsData.forEach((tag) => {
        if (tags.includes(tag._id)) {
          tagsScanned += 1;

          // each character = 7px, padding and border = 14px, gap = 6px, last count tag width = 30px
          const itemLength = tag.name.length * 7 + 14 + 6;

          if (totalLength + itemLength < tagWrapperWidth - 30) {
            totalLength += itemLength;
            _displayableTags.push(tag);
          }
        }
      });

      setDisplayableTags(_displayableTags);

      if (tagsScanned < tags.length) {
        // find deleted tags
        const deletedTags = tags.filter((id) => !tagsData.some((f) => f._id === id));

        // delete the tags from proposal, which might have failed to delete due to some reason
        if (deletedTags.length) {
          const newProposalList = proposalList.map((proposal) => {
            let newProposal = { ...proposal };

            if (newProposal?.tags) {
              // delete the tag from proposal
              newProposal.tags = newProposal.tags.filter((id) => !deletedTags.includes(id));
              updateProposalTags(newProposal, [...new Set(newProposal.tags)]);
            }

            return newProposal;
          });
          proposalsVar(newProposalList);
        }
      }
    }
  };

  const onDragEnd = (updatedTags) => {
    setUpdatedTags(updatedTags.map((t) => t._id));
  };

  const ignoreUpdate = () => {
    setIgnoreUpdates((prev) => {
      const newData = { ...prev };
      const currentTime = Date.now();

      // delete expired keys
      Object.keys(newData).forEach((key) => {
        if (newData[key] < currentTime) {
          delete newData[key];
        }
      });

      // ignore accepting BE response for this proposal for next 15 seconds
      // (to prevent dancing effect)
      newData[pid] = currentTime + 15000;

      return newData;
    });
  };

  const createTagHandle = (tagName, color, tagType) => {
    tagName = sanitizeString(tagName.trim());

    if (!tagName.length || !color || !tagType) {
      return;
    }

    setTagsLoader(true);

    addTag({
      variables: {
        name: tagName,
        color: color,
        type: tagType,
        auid,
        pid: [pid],
      },
    });
  };

  const handleEditTag = (tagId, name, color, type) => {
    if (!tagId) {
      return;
    }

    const index = tagsData.findIndex((tag) => tag._id === tagId);
    if (index < 0) {
      return;
    }

    const newData = { ...tagsData[index] };

    if (color && type) {
      newData.color = color;
      newData.type = type;
    }
    if (name) {
      name = sanitizeString(name.trim());
      if (name) {
        newData.name = name;
      }
    }

    updateTag({
      variables: { ...newData },
    });

    // update the tag in tagsData with latest value
    setTagsData((prev) => {
      const newTagData = [...prev];
      newTagData[index] = { ...newData };
      return newTagData;
    });

    // update tag in all tags searched array
    if (searchedAlltags.length) {
      setSearchedAlltags(searchedAlltags.map((tag) => (tag._id === tagId ? newData : tag)));
    }
  };

  const handleTagChecked = (tagId, checked) => {
    let newTags = [];

    if (checked) {
      const checkedTag = tagsData.find((tag) => tag._id === tagId);
      if (checkedTag) {
        newTags = [...tags, tagId];
      }
    } else {
      newTags = tags.filter((id) => id !== tagId);
    }

    getSortedCheckedUncheckedTags(tagsData, newTags);

    // find all tags which are selected in filter
    let selectTags = [];
    if (!checked) {
      selectTags = tagsFilter.filter((t) => t._id && t.checked).map((t) => t._id);
    }

    // update current proposal in all proposals list
    const newProposalList = proposalList
      .map((proposal) => {
        let newproposal = { ...proposal };
        let shouldkeep = true;

        if (proposal._id === pid) {
          newproposal.tags = newTags;

          if (!checked && selectTags.length) {
            // remove the proposal from list if it doesnt have any 1 of the filtered tag
            shouldkeep = selectTags.some((st) => newproposal.tags.includes(st));
          }
          // proposal will be updated once modal is closed
          setUpdatedProposal(newproposal);
          ignoreUpdate();
        }

        if (!shouldkeep) {
          // update proposal before it is deleted from ui
          updateProposalTags(newproposal, [...new Set(newproposal.tags)]);
          return null;
        }

        return newproposal;
      })
      .filter(Boolean);
    proposalsVar(newProposalList);
  };

  const deleteTagHandle = (tagId) => {
    setOpenDeletePopover('');

    deleteTag({
      variables: { _id: tagId },
    });

    // remove from tags data
    const newTagsData = tagsData.filter((f) => f._id !== tagId);

    // remove the tag from current proposal
    getSortedCheckedUncheckedTags(
      newTagsData,
      tags.filter((id) => id !== tagId)
    );

    setTagsData(newTagsData);

    // remove the id from updated tags
    setUpdatedTags((prev) => (prev ? prev.filter((id) => id !== tagId) : null));

    // remove from all proposals
    const newProposalList = proposalList.map((proposal) => {
      // remove the delete tag from all tags from proposal
      if (proposal?.tags) {
        proposal.tags = proposal.tags.filter((id) => id !== tagId);
      }
      return proposal;
    });
    proposalsVar(newProposalList);
  };

  const [addTag] = useMutation(ADD_TAG, {
    onCompleted: ({ addTag }) => {
      setTagsLoader(false);
      setCreateTag(false);
      setSearchText('');

      // add the tag to tags data
      const newTagsData = [...tagsData, addTag];
      setTagsData(newTagsData);

      const newTags = [...tags, addTag._id];
      getSortedCheckedUncheckedTags(newTagsData, newTags);

      // add the tag to current proposl
      const newProposalList = proposalList.map((proposal) => {
        if (proposal._id === pid) {
          // add the new tag to current proposal
          return { ...proposal, tags: newTags };
        }
        return proposal;
      });
      proposalsVar(newProposalList);
    },
    onError: (err) => {
      console.log('error', err);
    },
  });

  const [updateTag] = useMutation(UPDATE_TAG, {
    onCompleted: () => {},
    onError: (err) => {
      console.log('error', err);
    },
  });

  const [updateTagsOrder] = useMutation(UPDATE_TAG_ORDER, {
    onCompleted: () => {},
    onError: (err) => {
      console.log('error', err);
    },
  });

  const [deleteTag] = useMutation(DELETE_TAG, {
    onCompleted: () => {},
    onError: (err) => {
      console.log('error', err);
    },
  });

  const handleKeyCommandTags = (e) => {
    if (e.key === 'Escape') {
      window.removeEventListener('keydown', handleKeyCommand, true);
      setvisibleTags(false);
    }
  };

  const isDuplicate = (tagName, color, tagType, _id = null) => {
    // check if tag with name name, color and type exists
    const isExists = tagsData.find(
      (t) => t.name === tagName && t.color === color && (_id ? _id !== t._id : true)
    );
    return isExists && isExists.type === tagType;
  };

  const close = () => {
    setvisibleTags(false);
    window.removeEventListener('keydown', handleKeyCommandTags, true);
  };

  return (
    <Popover
      trigger="click"
      placement="right"
      destroyTooltipOnHide={true}
      overlayClassName="tag-popover"
      onVisibleChange={() => {
        if (visibleTags) {
          close();
        } else {
          setvisibleTags(true);
          window.addEventListener('keydown', handleKeyCommandTags, true);
        }
      }}
      visible={visibleTags}
      content={
        <div className="body">
          <div className="closeable-mask" onClick={close} />

          {createTag || createTag === '' ? (
            <CreateTags
              isDuplicate={isDuplicate}
              createTag={createTag}
              setCreateTag={setCreateTag}
              createTagHandle={createTagHandle}
              tagsLoader={tagsLoader}
            />
          ) : (
            <ManageTags
              isDuplicate={isDuplicate}
              allCheckedTags={allCheckedTags}
              allUncheckedTags={allUncheckedTags}
              editTags={editTags}
              setEditTags={setEditTags}
              handleEditTag={handleEditTag}
              selectedTagColor={selectedTagColor}
              setSelectedTagColor={setSelectedTagColor}
              deleteTag={deleteTagHandle}
              openDeletePopover={openDeletePopover}
              setOpenDeletePopover={setOpenDeletePopover}
              searchText={searchText}
              setSearchText={setSearchText}
              searchedChecked={searchedChecked}
              setSearchedChecked={setSearchedChecked}
              searchedUnchecked={searchedUnchecked}
              setSearchedUnchecked={setSearchedUnchecked}
              searchedAlltags={searchedAlltags}
              setSearchedAlltags={setSearchedAlltags}
              setCreateTag={setCreateTag}
              handleTagChecked={handleTagChecked}
              visibleTags={visibleTags}
              onDragEnd={onDragEnd}
              tagsData={tagsData}
              setTagsData={setTagsData}
            />
          )}
        </div>
      }>
      <div className="proposal-tags" onClick={(e) => e.stopPropagation()}>
        {displayableTags?.length ? (
          <>
            {displayableTags.map((tag) => (
              <TagBadge
                className="list-tags"
                key={tag._id}
                type={tag.type}
                color={tag.color}
                name={
                  tagWrapperWidth < 160 && tag.name.length > 10
                    ? `${tag.name.substring(0, 10)}...`
                    : tag.name
                }
              />
            ))}
            {displayableTags.length !== tags.length && (
              <TagBadge
                className="list-tags count-tag"
                type="border"
                color="#006655"
                name={`+${tags.length - displayableTags.length}`}
              />
            )}
          </>
        ) : (
          <Button className="add-tags">+ Add Tag</Button>
        )}
      </div>
    </Popover>
  );
};

ProposalItemTag.propTypes = {
  tags: PropTypes.array,
  tagsData: PropTypes.array,
  pid: PropTypes.string,
  auid: PropTypes.string,
  visibleTags: PropTypes.bool,
  setvisibleTags: PropTypes.func,
  handleKeyCommand: PropTypes.func,
  tagWrapperWidth: PropTypes.number,
  setTagsData: PropTypes.func,
  proposalsVar: PropTypes.func,
  proposalList: PropTypes.array,
  updateProposalTags: PropTypes.func,
};

export default ProposalItemTag;
