import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.46777 3.19068C5.30542 2.25646 6.53098 1.66724 7.89635 1.66724C10.4211 1.66724 12.4678 3.68196 12.4678 6.16724C12.4678 8.65252 10.4211 10.6672 7.89634 10.6672C6.54244 10.6672 5.326 10.0879 4.48894 9.16724"
      stroke="#05034D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.9545 5.34735C2.67087 5.35281 2.43952 5.12146 2.44498 4.83783L2.49511 2.23163C2.50359 1.79088 3.03686 1.57598 3.34857 1.88769L5.90463 4.44375C6.21635 4.75547 6.00145 5.28873 5.5607 5.29721L2.9545 5.34735Z"
      fill="#05034D"
    />
  </svg>
);

const SettingsResetIcon = (props) => <Icon component={svg} {...props} />;

export default SettingsResetIcon;
