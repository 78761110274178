import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 8H4C2.89333 8 1.95 7.60954 1.17 6.82862C0.39 6.04771 0 5.10326 0 3.99529C0 2.88732 0.39 1.94444 1.17 1.16667C1.95 0.388889 2.89333 0 4 0H7V1.5H4C3.30556 1.5 2.71528 1.74306 2.22917 2.22917C1.74306 2.71528 1.5 3.30556 1.5 4C1.5 4.69444 1.74306 5.28472 2.22917 5.77083C2.71528 6.25694 3.30556 6.5 4 6.5H7V8ZM5 4.75V3.25H11V4.75H5ZM9 8V6.5H12C12.6944 6.5 13.2847 6.25694 13.7708 5.77083C14.2569 5.28472 14.5 4.69444 14.5 4C14.5 3.30556 14.2569 2.71528 13.7708 2.22917C13.2847 1.74306 12.6944 1.5 12 1.5H9V0H12C13.1067 0 14.05 0.390459 14.83 1.17138C15.61 1.95229 16 2.89674 16 4.00471C16 5.11268 15.61 6.05556 14.83 6.83333C14.05 7.61111 13.1067 8 12 8H9Z"
      fill="#5D5AB5"
    />
  </svg>
);

const InputLinkIcon = (props) => <Icon component={svg} {...props} />;

export default InputLinkIcon;
