import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Divider, Button, Tooltip, Collapse } from 'antd';

import {
  SectionAddIcon,
  PlusCircleIcon,
  ChevronDownIcon,
  SearchLightIcon,
  VariablesEditIcon,
  BackArrowIcon,
} from 'components/Icons';

import './ProposalItemTag.scss';
import TagCheckboxes from './TagCheckboxes';
import EditTagCheckboxs from './EditTagCheckboxs';
import { escapeRegExp } from 'utils/xss';

const { Panel } = Collapse;

const ManageTags = ({
  allCheckedTags,
  allUncheckedTags,
  editTags,
  setEditTags,
  handleEditTag,
  selectedTagColor,
  setSelectedTagColor,
  deleteTag,
  openDeletePopover,
  setOpenDeletePopover,
  searchText,
  setSearchText,
  searchedChecked,
  setSearchedChecked,
  searchedUnchecked,
  setSearchedUnchecked,
  searchedAlltags,
  setSearchedAlltags,
  setCreateTag,
  handleTagChecked,
  visibleTags,
  onDragEnd,
  tagsData,
  setTagsData,
  isDuplicate,
}) => {
  const searchInputRef = useRef(null);

  const noResult =
    (searchText && !searchedChecked.length && !searchedUnchecked.length) ||
    (!searchText && !allCheckedTags.length && !allUncheckedTags.length);

  useEffect(() => {
    if (visibleTags && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [visibleTags, searchInputRef]);

  const searchTags = (e) => {
    const text = e.target.value;

    if (!text?.length) {
      setSearchText('');
      return;
    }

    const searchedTagsData = searchArray(tagsData, new RegExp(escapeRegExp(text), 'i'));
    const searchedTagsDataIds = searchedTagsData.map((t) => t._id);
    setSearchedAlltags(searchedTagsData);

    setSearchedChecked(
      allCheckedTags.filter((t) => searchedTagsDataIds.includes(t._id)).map((t) => t._id)
    );

    setSearchedUnchecked(
      allUncheckedTags.filter((t) => searchedTagsDataIds.includes(t._id)).map((t) => t._id)
    );

    setSearchText(text);
  };

  const searchArray = (arr, regex) => {
    return arr.filter((tag) => regex.test(tag.name));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (noResult) {
        // for to create tag screen
        setCreateTag(searchText || '');
      }
    }
  };

  // not editing, searching and tags not found
  const editDisabled = (!editTags && searchText && !searchedAlltags.length) || !tagsData.length;

  const editButton = (
    <Button
      type="primary"
      size="small"
      disabled={editDisabled}
      className={`small-btn grey-btn edit-tags-icon ${editTags ? 'active' : 'inactive'}`}
      onClick={() => {
        if (!noResult) {
          setEditTags(!editTags);
        }
      }}
      icon={<VariablesEditIcon />}
    />
  );

  const unCheckedTagList = (
    <TagCheckboxes
      className="unchecked-tags"
      tags={
        searchText
          ? allUncheckedTags.filter((t) => searchedUnchecked.includes(t._id))
          : allUncheckedTags
      }
      handleTagChecked={handleTagChecked}
      isSearching={searchText}
    />
  );

  if (!visibleTags) {
    return null;
  }

  return (
    <div className="tags-card" onClick={(e) => e.stopPropagation()}>
      <div className="tag-header">
        <div className="tag-title">Manage Tags</div>
        <div className="action-buttons">
          <Button
            type="primary"
            size="small"
            className="small-btn grey-btn add-tag-icon"
            onClick={() => setCreateTag(searchText || '')}
            icon={<PlusCircleIcon />}>
            New
          </Button>

          {editTags ? (
            <Button
              type="primary"
              size="small"
              className="small-btn grey-btn "
              onClick={() => setEditTags(false)}
              icon={<BackArrowIcon className="back-tag-icon" />}>
              Back
            </Button>
          ) : editDisabled ? (
            editButton
          ) : (
            <Tooltip title="Edit tags">{editButton}</Tooltip>
          )}
        </div>
      </div>

      <Input
        className="search-tags"
        allowClear
        bordered={false}
        maxLength={25}
        placeholder="Search or create new tags"
        value={searchText}
        onChange={searchTags}
        onKeyDown={handleKeyDown}
        prefix={<SearchLightIcon className="search-icon" />}
        ref={searchInputRef}
      />

      <Divider className="tags-divider" />

      <div className="tags-card-body">
        {noResult && (
          <div className="create-new-tag">
            {searchText && (
              <p className="add-new-tag-text">
                Do you want to create new &nbsp;<b>"{searchText}"</b>&nbsp; tag ?
              </p>
            )}

            <Button
              type="primary"
              size="middle"
              className="medium-btn secondary-btn create-new-tag-button"
              onClick={() => {
                setCreateTag(searchText);
              }}
              icon={<SectionAddIcon className="create-tag-button-icon" />}>
              Create Tag
            </Button>
          </div>
        )}

        {
          // checked tag in edit mode
          editTags ? (
            <EditTagCheckboxs
              className="checked-tags"
              tags={searchText ? searchedAlltags : tagsData}
              handleEditTag={handleEditTag}
              openDeletePopover={openDeletePopover}
              setOpenDeletePopover={setOpenDeletePopover}
              isSearching={searchText}
              deleteTagFromProposal={deleteTag}
              newColor={selectedTagColor}
              setColor={setSelectedTagColor}
              setTags={setTagsData}
              onDragEnd={onDragEnd}
              isDuplicate={isDuplicate}
            />
          ) : (
            <>
              {/* checked tags in non edit more*/}
              <TagCheckboxes
                className="checked-tags"
                tags={
                  searchText
                    ? allCheckedTags.filter((t) => searchedChecked.includes(t._id))
                    : allCheckedTags
                }
                handleTagChecked={handleTagChecked}
                defaultChecked={true}
              />

              {
                // selected tags and unselected tags separator
                (searchText && searchedChecked.length > 0 && searchedUnchecked.length > 0) ||
                (!searchText && allCheckedTags.length > 0 && allUncheckedTags.length > 0) ? (
                  <Collapse
                    ghost
                    bordered={false}
                    defaultActiveKey={['1']}
                    expandIcon={({ isActive }) => (
                      <ChevronDownIcon
                        style={{
                          transition: '0.2s',
                          transform: `rotate(${isActive ? 0 : -90}deg)`,
                        }}
                      />
                    )}
                    className="site-collapse-custom-collapse">
                    <Panel header="Add more tags" key="1">
                      {unCheckedTagList}
                    </Panel>
                  </Collapse>
                ) : (
                  unCheckedTagList
                )
              }
            </>
          )
        }
      </div>
    </div>
  );
};

ManageTags.propTypes = {
  allCheckedTags: PropTypes.array,
  allUncheckedTags: PropTypes.array,
  editTags: PropTypes.bool,
  setEditTags: PropTypes.func,
  handleEditTag: PropTypes.func,
  selectedTagColor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setSelectedTagColor: PropTypes.func,
  deleteTag: PropTypes.func,
  openDeletePopover: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setOpenDeletePopover: PropTypes.func,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  searchedChecked: PropTypes.array,
  setSearchedChecked: PropTypes.func,
  searchedUnchecked: PropTypes.array,
  setSearchedUnchecked: PropTypes.func,
  searchedAlltags: PropTypes.array,
  setSearchedAlltags: PropTypes.func,
  setCreateTag: PropTypes.func,
  handleTagChecked: PropTypes.func,
  visibleTags: PropTypes.bool,
  onDragEnd: PropTypes.func,
  tagsData: PropTypes.array,
  setTagsData: PropTypes.func,
};

export default ManageTags;
