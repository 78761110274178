import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import TagBadge from 'components/TagBadge';

// checkbox with tag button
const TagCheckboxes = ({ defaultChecked, handleTagChecked, className, tags }) => {
  if (!tags?.length) {
    return null;
  }

  return (
    <div className={`${className || ''} tags-list`}>
      {tags.map((tagData) => (
        <ListItem
          key={tagData._id}
          tagData={tagData}
          defaultChecked={defaultChecked}
          handleTagChecked={handleTagChecked}
        />
      ))}
    </div>
  );
};

TagCheckboxes.propTypes = {
  defaultChecked: PropTypes.bool,
  handleTagChecked: PropTypes.func,
  className: PropTypes.string,
  tags: PropTypes.array,
};

export default TagCheckboxes;

const ListItem = ({ tagData, defaultChecked, handleTagChecked }) => {
  return (
    <li className="tag-li" key={tagData._id}>
      <Checkbox
        defaultChecked={defaultChecked}
        className="tag-checkbox"
        onChange={(e) => {
          if (handleTagChecked) {
            handleTagChecked(tagData._id, e.target.checked, tagData);
          }
        }}>
        <TagBadge {...tagData} />
      </Checkbox>
    </li>
  );
};
