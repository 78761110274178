import PropTypes from 'prop-types';
import { DragIcon } from 'components/Icons';

const DragHandle = ({ className, style, ...rest }) => {
  return (
    <div className={`drag-handle ${className}`} {...rest}>
      <DragIcon />
    </div>
  );
};
export default DragHandle;

DragHandle.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};
DragHandle.defaultProps = {
  className: '',
  style: null,
};
