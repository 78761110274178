import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2929 6.00002H0V5.00002H12.2929L8.14522 0.860389C7.94996 0.665127 7.94996 0.348545 8.14522 0.153283C8.34048 -0.0419796 8.65706 -0.0419796 8.85232 0.153283L14.2071 5.50002L8.85232 10.8553C8.65706 11.0506 8.34048 11.0506 8.14522 10.8553C7.94996 10.6601 7.94996 10.3435 8.14522 10.1482L12.2929 6.00002Z"
      fill="white"
    />
  </svg>
);

const ArrowRightIcon = (props) => <Icon component={svg} {...props} />;

export default ArrowRightIcon;
