import React, { useState, useEffect } from 'react';
import { Col, Row, List, Divider } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Button from 'components/Button';
import Input from 'components/Input';
import UnlinedTick from 'components/Icons/UnlinedTick';
import DeleteIcon from 'components/Icons/DeleteIcon';
import AddBlockIcon from 'components/Icons/AddBlockIcon';
import DownloadCircleIcon from 'components/Icons/DownloadCircleIcon';
import { sanitizeString } from 'utils/xss';

const Prelist = ({
  chosen,
  tabType,
  prelist,
  addNewItem,
  addItem,
  removeItem,
  handleState,
  wixConnectedInstance,
  fetchMoreWixProductsAndServicesLoading,
  fetchMoreWixProductsAndServices,
}) => {
  const [filteredprelist, setfilteredprelist] = useState(prelist);
  const [newPrelistItem, setNewPrelistItem] = useState('');
  const [defaultServicesListVisibility, setDefaultServicesListVisibility] = useState(true);
  const [wixServicesListVisibility, setWixServicesListVisibility] = useState(true);
  const [wixProductsListVisibility, setWixProductsListVisibility] = useState(true);
  const [deletedItems, setDeletedItems] = useState([]);
  const [newItemName, setNewItemName] = useState('');

  useEffect(() => {
    const tempPrelist = [];
    if (chosen && (tabType === 'super' || tabType === 'miles')) {
      // return if chosen is empty
      if (Object.keys(chosen).length === 0) {
        return;
      }
      prelist.forEach((i) => {
        chosen['OPTION 1'].list.forEach((x) => {
          x.name === i.name ? (i.selected = true) : '';
        });
        tempPrelist.push(i);
      });
      updatefilteredprelist(tempPrelist);
    } else if (chosen && tabType === 'delivs') {
      prelist.forEach((i) => {
        if (i.name === 'wixServices') {
          i['wixServices'].forEach((wS) => {
            _.forOwn(chosen, (chosendata) =>
              chosendata.list?.forEach((l) => {
                l.name === wS.name && l.description === wS.description ? (wS.selected = true) : '';
              })
            );
          });
        } else if (i.name === 'wixProducts') {
          i['wixProducts'].forEach((wP) => {
            _.forOwn(chosen, (chosendata) =>
              chosendata.list?.forEach((l) => {
                l.name === wP.name &&
                l.description === wP.description &&
                Number(l.price) === Number(wP.price)
                  ? (wP.selected = true)
                  : '';
              })
            );
          });
        } else if (i.name === 'defaultServices') {
          i['defaultServices'].forEach((dS) => {
            _.forOwn(chosen, (chosendata) =>
              chosendata.list?.forEach((l) => {
                l.name === dS.name ? (dS.selected = true) : '';
              })
            );
          });
        } else {
          _.forOwn(chosen, (chosendata) =>
            chosendata.list?.forEach((l) => {
              l.name === i.name ? (i.selected = true) : '';
            })
          );
        }
        tempPrelist.push(i);
      });
      updatefilteredprelist(tempPrelist);
    } else {
      updatefilteredprelist(prelist);
    }
  }, [prelist, chosen]); //eslint-disable-line

  const onAddNew = (name) => {
    name = sanitizeString(name).trim();
    if (!name.length) {
      return;
    }
    addNewItem(name);
    setNewPrelistItem('');
    setNewItemName(name);

    // remove from deleted array
    setDeletedItems((prev) => prev.filter((item) => item !== name));
  };

  const onDelete = (name) => {
    setDeletedItems((prev) => {
      const newData = [...new Set([...prev, name])];
      // remove from new item
      if (name === newItemName) {
        setNewItemName('');
      }
      // remove deleted items from array
      updatefilteredprelist(filteredprelist, newData);
      return newData;
    });

    removeItem(name);
  };

  const loadMoreWixProductsAndServices = (type, totalItems) => {
    fetchMoreWixProductsAndServices({
      variables: {
        instanceId: wixConnectedInstance,
        type: type || 'products',
        offset: (Math.floor(totalItems / 50) + 1 - 1) * 50,
      },
    });
  };

  const updatefilteredprelist = (data, deletedItemNames = deletedItems) => {
    data = filterItems(data, deletedItemNames);

    if (newItemName.length) {
      // add the newly added item
      let addedNewItem = { index: 0, name: null };

      data.forEach((d, index) => {
        if (d.name === 'defaultServices') {
          addedNewItem = { index: index, name: 'defaultServices' };
        }
      });

      // add last added item to list
      const arr = addedNewItem.name ? data[addedNewItem.index][addedNewItem.name] : data;
      const lastitem = arr[arr.length - 1];

      if (lastitem && lastitem.name !== newItemName) {
        const newItem = {
          editable: true,
          selected: true,
          name: newItemName,
        };

        if (addedNewItem.name) {
          data[addedNewItem.index][addedNewItem.name].push(newItem);
        } else {
          data.push(newItem);
        }
      }
    }

    setfilteredprelist(data);
  };

  const filterItems = (data, deletedItems) => {
    // exclude deleted items
    const excludeNames = deletedItems.reduce((a, v) => ({ ...a, [v]: true }), {});

    return data
      .map((d) => {
        if (d.name === 'wixServices' || d.name === 'wixProducts' || d.name === 'defaultServices') {
          d[d.name] = filterItems(d[d.name], deletedItems);
        } else {
          if (d.editable) {
            if (excludeNames[d.name]) {
              return null;
            }

            // dont include same item twice, so set the value in excludeNames list
            excludeNames[d.name] = true;
          }
        }

        return d;
      })
      .filter(Boolean);
  };

  const renderListItems = (item) => {
    let listItemInfo = {
      innerDataSource: [],
      onClickAction: () => {},
      innerIconComponent: '',
      visibility: false,
      title: '',
      listClassName: '',
    };

    if (item.name === 'wixServices' && item['wixServices'].length) {
      listItemInfo = {
        title: 'Your Wix services',
        type: 'services',
        innerDataSource: item['wixServices'],
        onClickAction: () => setWixServicesListVisibility(!wixServicesListVisibility),
        innerIconComponent: wixServicesListVisibility ? <UpOutlined /> : <DownOutlined />,
        visibility: wixServicesListVisibility,
        listClassName: 'tablist-prelist-inner',
      };
    } else if (item.name === 'wixProducts' && item['wixProducts'].length) {
      listItemInfo = {
        title: 'Your Wix products',
        type: 'products',
        innerDataSource: item['wixProducts'],
        onClickAction: () => setWixProductsListVisibility(!wixProductsListVisibility),
        innerIconComponent: wixProductsListVisibility ? <UpOutlined /> : <DownOutlined />,
        visibility: wixProductsListVisibility,
        listClassName: 'tablist-prelist-inner',
      };
    } else if (item.name === 'defaultServices' && item['defaultServices'].length) {
      listItemInfo = {
        title: 'Default services',
        innerDataSource: item['defaultServices'],
        onClickAction: () => setDefaultServicesListVisibility(!defaultServicesListVisibility),
        innerIconComponent: defaultServicesListVisibility ? <UpOutlined /> : <DownOutlined />,
        visibility: defaultServicesListVisibility,
        listClassName: 'tablist-prelist-inner',
      };
    } else if (
      item.name !== 'wixServices' &&
      item.name !== 'wixProducts' &&
      item.name !== 'defaultServices'
    ) {
      listItemInfo = {
        title: (
          <>
            {item.logo && <img alt="Wix" src={item['logo']} />}
            {item.name}
          </>
        ),
        onClickAction:
          item.selected && tabType === 'super'
            ? () => {}
            : (value) => addItem(item.name)(value, chosen),
        innerIconComponent: (
          <>
            {item.editable && (
              <>
                <DeleteIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(item.name);
                  }}
                />
                <Divider type="vertical" />
              </>
            )}
            {item.selected && <UnlinedTick />}
          </>
        ),
        visibility: false,
        listClassName: item.selected ? 'selected' : '',
      };
    } else {
      return <></>;
    }

    return (
      <>
        <List.Item
          key={item.name}
          className={listItemInfo.listClassName}
          onClick={listItemInfo.onClickAction}>
          <List.Item.Meta title={listItemInfo.title} />
          {listItemInfo.innerIconComponent}
        </List.Item>
        {listItemInfo.visibility && (
          <List
            dataSource={listItemInfo.innerDataSource}
            loadMore={
              listItemInfo.innerDataSource?.length < item?.totalItems ? (
                <Button
                  type="default"
                  size="medium"
                  className="tablist-prelist-load-more"
                  text="Load more"
                  icon={<DownloadCircleIcon />}
                  loading={fetchMoreWixProductsAndServicesLoading}
                  onClick={() =>
                    loadMoreWixProductsAndServices(
                      listItemInfo?.type,
                      listItemInfo.innerDataSource?.length
                    )
                  }
                />
              ) : null
            }
            renderItem={(innerItem, innerItemIndex) => (
              <List.Item
                key={`${innerItem.name}-${innerItemIndex}`}
                onClick={
                  innerItem.selected && tabType === 'super'
                    ? () => {}
                    : (value) =>
                        addItem(
                          innerItem.name,
                          innerItem.description,
                          Number(innerItem?.price || 0)
                        )(value, chosen)
                }
                className={innerItem.selected ? 'selected' : ''}>
                <List.Item.Meta title={innerItem.name} />
                {innerItem.editable && (
                  <>
                    <DeleteIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(innerItem.name);
                      }}
                    />
                    <Divider type="vertical" />
                  </>
                )}

                {innerItem.selected && <UnlinedTick />}
              </List.Item>
            )}
          />
        )}
      </>
    );
  };

  return (
    <Col className="tablist-prelist">
      <Row className="tablist-prelist-input">
        <Input
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              onAddNew(e.target.value);
            }
          }}
          suffix={
            <AddBlockIcon
              onClick={() => {
                onAddNew(newPrelistItem);
              }}
            />
          }
          onChange={(e) => {
            handleState(tabType, e.target.value);
            setNewPrelistItem(e.target.value);
            setNewItemName('');
          }}
          defaultValue={newPrelistItem}
          value={newPrelistItem}
          placeholder="Search or Add Item(↵ to add)"
        />
      </Row>
      <Row className="tablist-prelist-content">
        <List dataSource={filteredprelist} renderItem={renderListItems} />
      </Row>
    </Col>
  );
};

Prelist.defaultProps = {
  chosen: '',
  wixConnectedInstance: '',
  fetchMoreWixProductsAndServicesLoading: false,
};

Prelist.propTypes = {
  prelist: PropTypes.instanceOf(Array).isRequired,
  chosen: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  tabType: PropTypes.string.isRequired,
  addItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  addNewItem: PropTypes.func.isRequired,
  handleState: PropTypes.func.isRequired,
  fetchMoreWixProductsAndServicesLoading: PropTypes.bool,
  fetchMoreWixProductsAndServices: PropTypes.func.isRequired,
  wixConnectedInstance: PropTypes.string,
};

export default Prelist;
