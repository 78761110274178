import { gql } from '@apollo/client';

export const FETCH_TAGS = gql`
  query fetchTags($auid: String!) {
    fetchTags(auid: $auid) {
      _id
      name
      type
      color
    }
  }
`;
