import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33301 4.00004V2.66671C3.33301 2.31309 3.47348 1.97395 3.72353 1.7239C3.97358 1.47385 4.31272 1.33337 4.66634 1.33337H7.33301C7.68663 1.33337 8.02577 1.47385 8.27582 1.7239C8.52587 1.97395 8.66634 2.31309 8.66634 2.66671V4.00004M10.6663 4.00004V13.3334C10.6663 13.687 10.5259 14.0261 10.2758 14.2762C10.0258 14.5262 9.68663 14.6667 9.33301 14.6667H2.66634C2.31272 14.6667 1.97358 14.5262 1.72353 14.2762C1.47348 14.0261 1.33301 13.687 1.33301 13.3334V4.00004H10.6663Z"
      stroke="#FF1B1B"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SelectBinIcon = (props) => <Icon component={svg} {...props} />;

export default SelectBinIcon;
