import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12363_23945)">
      <path
        d="M7.99967 14.6666C11.6816 14.6666 14.6663 11.6819 14.6663 7.99998C14.6663 4.31808 11.6816 1.33331 7.99967 1.33331C4.31778 1.33331 1.33301 4.31808 1.33301 7.99998C1.33301 11.6819 4.31778 14.6666 7.99967 14.6666Z"
        stroke="#4F4E7B"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.6667V8"
        stroke="#4F4E7B"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.33331H8.00667"
        stroke="#4F4E7B"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12363_23945">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const UploadInfoIcon = (props) => <Icon component={svg} {...props} />;

export default UploadInfoIcon;
