import _ from 'lodash';
import copyText from 'copy-to-clipboard';
import { message } from 'antd';

// Regex to validate URL
const urlPattern = /^(ftp|http|https):\/\/[^ "]+\.[^ "]+$/;

export const isValidUrlFormat = (url) => {
  return urlPattern.test(url);
};

export const urlMatch = (url) => {
  return url.match(urlPattern);
};

export const copySelectedLink = (link) => {
  copyText(link);
  message.success('Copied to Clipboard');
};

// get the style to render hyperlink input modal
export const getLinkInputStyle = (state, numberOfColumns = 1, columnIndex = 0) => {
  const style = _.extend({}, state.currentCursorOffset.position);
  style.top = style.top + 60;

  // prevent going out of screen
  if (numberOfColumns === 1) {
    if (style.left > 200) {
      delete style.left;
      style.right = -38;
    }
  } else if (numberOfColumns === 2 && columnIndex === 0) {
    style.left = Math.min(style.left, 170);
  } else if (
    (numberOfColumns === 4 && columnIndex === 2) ||
    (numberOfColumns === 3 && columnIndex === 1)
  ) {
    style.left = -98;
  } else if (
    (numberOfColumns === 4 && columnIndex === 3) ||
    (numberOfColumns === 3 && columnIndex === 2) ||
    (numberOfColumns === 2 && columnIndex === 1)
  ) {
    delete style.left;
    style.right = -38;
  }

  return style;
};
