import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import _ from 'lodash';
import { EditorState, Modifier } from 'draft-js';

const RenderColorPicker = ({ state, onToggle, className }) => {
  const [color, setColor] = useState('');

  const TABS = [
    {
      style: 'COLOR',
      text: 'Text',
    },
    {
      style: 'BACKGROUND',
      text: 'Highlight',
    },
  ];

  useEffect(() => {
    const { editorState } = state;
    const currentStyle = editorState.getCurrentInlineStyle();
    let color = '';

    if (state.displayColorPicker === 'COLOR') {
      color =
        currentStyle.filter((value) => value?.toString()?.startsWith('rgba')).last() || '#4a4a4a';
    } else {
      color =
        currentStyle.filter((value) => value?.toString()?.startsWith('bg-')).last() ||
        'rgba(255,255,255,1)';
      if (color?.startsWith('bg-')) {
        color = color.substring(3);
      }
    }

    setColor(color);
  }, [state]);

  const clearInlineColorStyles = (es, type) => {
    const selection = es.getSelection();
    const contentState = es.getCurrentContent();
    const styles = [];
    // regex for COLOR = rgba should not have bg- infront of rgba
    // regex for BACKGROUND = it will match bg-rgba
    const testPattern = type === 'COLOR' ? /^(?!.*\bbg-.*\brgba\b).*\brgba\b/m : /\b(bg-rgba)\b/m;

    if (selection.isCollapsed()) {
      es.getCurrentInlineStyle().forEach(
        (style) => style && testPattern.test(style) && styles.push(style)
      );
    } else {
      let key = selection.getStartKey();
      let startOffset = selection.getStartOffset();
      const endKey = selection.getEndKey();
      const endOffset = selection.getEndOffset();

      while (true) {
        const lastRound = key === endKey;
        const block = contentState.getBlockForKey(key);
        const offsetEnd = lastRound ? endOffset : block.getLength();
        const characterList = block.getCharacterList();
        for (let offsetIndex = startOffset; offsetIndex < offsetEnd; offsetIndex++)
          characterList
            .get(offsetIndex)
            .getStyle()
            .forEach((style) => style && testPattern.test(style) && styles.push(style));
        if (lastRound) break;
        key = contentState.getKeyAfter(key);
        startOffset = 0;
      }
    }

    const contentWithoutStyles = _.reduce(
      styles,
      (newContentState, style) =>
        Modifier.removeInlineStyle(newContentState, es.getSelection(), style),
      es.getCurrentContent()
    );
    return EditorState.push(es, contentWithoutStyles, 'change-inline-style');
  };

  const toggleColor = (toggledColor, type) => {
    const { editorState } = state;

    const currentEditorState = clearInlineColorStyles(editorState, type);
    let selectedColor = '';

    if (toggledColor) {
      if (typeof toggledColor === 'string') {
        selectedColor = toggledColor;
      } else {
        selectedColor = `rgba(${toggledColor.rgb.r}, ${toggledColor.rgb.g}, ${toggledColor.rgb.b}, ${toggledColor.rgb.a})`;
      }
    } else {
      const currentStyle = editorState.getCurrentInlineStyle();
      if (type === 'COLOR') {
        // for text color
        selectedColor =
          currentStyle.filter((value) => value?.toString()?.startsWith('rgba')).last() || '#4a4a4a';
      } else {
        // for background
        selectedColor =
          currentStyle.filter((value) => value?.toString()?.startsWith('bg-')).last() ||
          'rgba(255,255,255,1)';
      }
    }

    if (type === 'BACKGROUND' && !selectedColor.startsWith('bg-')) {
      selectedColor = `bg-${selectedColor}`;
    }

    onToggle({
      es: currentEditorState,
      inlineStyle: selectedColor,
    });

    /*
    below code is working - it will save style as entityMap as below but problem rendering
    if (type === 'BACKGROUND') {
      "entityMap": {
          "0": {
            "type": "BACKGROUND",
            "mutability": "IMMUTABLE",
            "data": { "color": "rgba(241, 19, 19, 1)" }
          },
        }

      const contentState = currentEditorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity('BACKGROUND', 'IMMUTABLE', {
        color: selectedColor,
      });
  
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const es = RichUtils.toggleLink(
        currentEditorState,
        state.selectionState || currentEditorState.getSelection(),
        entityKey
      );
  
      handleEditorChange({ es });
    }
    */
  };

  return (
    <div className={`color-picker ${className}`} onClick={(e) => e.stopPropagation()}>
      <div className="color-tabs">
        {TABS.map((tab) => (
          <div
            key={tab.text}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onToggle({ inlineStyle: tab.style });
            }}
            className={`color-tab ${state.displayColorPicker === tab.style ? 'active' : ''}`}>
            {tab.text}
          </div>
        ))}
      </div>
      <div onMouseDown={(e) => e.stopPropagation()}>
        <SketchPicker
          color={color}
          onChange={(toggledColor, e) => {
            e.stopPropagation();
            const _color = `rgba(${toggledColor.rgb.r}, ${toggledColor.rgb.g}, ${toggledColor.rgb.b}, ${toggledColor.rgb.a})`;

            setColor(_color);
            toggleColor(_color, state.displayColorPicker);
          }}
          presetColors={[]}
        />
      </div>
    </div>
  );
};

export default RenderColorPicker;
