import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Popover, Input, Divider, Button } from 'antd';
import { closestCenter, DndContext, PointerSensor, useSensor, DragOverlay } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import { DndWithHandle, DragHandle } from 'components/dnd';
import useDnd from 'hooks/useDnd';
import { CloseIcon, TrashIcon, DeleteIcon } from 'components/Icons';
import TagColorSelector from './TagColorSelector';

// edit tags
const EditTagCheckboxs = ({ className, tags, isSearching, setTags, onDragEnd, ...rest }) => {
  const { handleDragStart, handleDragEnd, dragItem } = useDnd(tags, setTags, onDragEnd, '_id');
  const sensors = [useSensor(PointerSensor)];

  if (!tags?.length) {
    return null;
  }

  const options = {
    ...rest,
  };

  // if not searching and there are > 1 tag the enable dragging
  if (!isSearching && tags.length > 1) {
    return (
      <div className={`${className || ''} dragable tags-list`}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}>
          <SortableContext
            items={tags.map((item) => item._id)}
            strategy={verticalListSortingStrategy}>
            {tags.map((tagData) => (
              <DndWithHandle key={tagData._id} id={tagData._id} className="edit-tag-list">
                <EditTagCheckbox {...tagData} {...options} />
              </DndWithHandle>
            ))}

            {/* this will be rendered when draging */}
            <DragOverlay modifiers={[restrictToVerticalAxis]}>
              {dragItem && (
                <div className="dnd-wrapper edit-tag-list drag-overlay">
                  <DragHandle />
                  <EditTagCheckbox {...dragItem} {...options} />
                </div>
              )}
            </DragOverlay>
          </SortableContext>
        </DndContext>
      </div>
    );
  }

  return (
    <div className={`${className || ''} tags-list`}>
      {tags.map((tagData) => (
        <div key={tagData._id} className="edit-tag-list">
          <EditTagCheckbox {...tagData} {...options} />
        </div>
      ))}
    </div>
  );
};

EditTagCheckboxs.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.array,
  newColor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setColor: PropTypes.func,
  handleEditTag: PropTypes.func,
  openDeletePopover: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setOpenDeletePopover: PropTypes.func,
  deleteTagFromProposal: PropTypes.func,
  isDuplicate: PropTypes.func,
};

export default EditTagCheckboxs;

const EditTagCheckbox = ({
  _id,
  name,
  color,
  type,
  handleEditTag,
  openDeletePopover,
  setOpenDeletePopover,
  deleteTagFromProposal,
  isDuplicate,
}) => {
  const inputRef = useRef(null);
  const [newName, setName] = useState(name);
  const [newColor, setColor] = useState(color);
  const [newType, setType] = useState(type);
  const [error, setError] = useState(false);

  const updateName = () => {
    if (newName && newName !== name) {
      // check duplicate
      if (isDuplicate(newName, newColor, newType, _id)) {
        setError(true);
        return;
      } else {
        setError(false);
      }

      handleEditTag(_id, newName, undefined, undefined);
    }
  };

  const updateColor = (color, type) => {
    // check duplicate
    if (isDuplicate(newName, color, type, _id)) {
      setError(true);
      return;
    } else {
      setError(false);
    }
    handleEditTag(_id, undefined, color, type);
    setColor(color);
    setType(type);
  };

  if (!newColor) {
    return null;
  }

  return (
    <div className="tag-list-item">
      <div className="tag-list-input">
        <div className={`name ${error ? 'error' : ''}`}>
          <Popover
            trigger="click"
            placement="left"
            className="edit-tag-popover"
            // onVisibleChange={() => {
            //   // setColor(false);
            // }}
            content={<TagColorSelector color={newColor} updateColor={updateColor} />}>
            <div
              key={_id}
              className="edit-tag-color-circle"
              style={{
                border: `2px solid ${newColor}`,
                background: newType === 'fill' ? newColor || color : '',
              }}
            />
          </Popover>
          <Divider className="edit-tag-list-divider" type="vertical" />
          <Input
            bordered={false}
            className="edit-tag-name"
            placeholder="Tag name"
            value={newName}
            onChange={(e) => {
              setName(e.target.value.substring(0, 25));
              if (error) {
                setError(false);
              }
            }}
            ref={inputRef}
            onPressEnter={() => {
              inputRef.current.blur();
            }}
            onBlur={updateName}
          />
        </div>
        <Popover
          trigger="click"
          placement="right"
          visible={openDeletePopover === _id}
          onVisibleChange={() => {
            setOpenDeletePopover(_id);
          }}
          content={
            <div className="delete-tag">
              <CloseIcon className="close-delete-tag" onClick={() => setOpenDeletePopover('')} />
              <TrashIcon className="delete-tag-icon" />
              <p className="delete-tag-text">Delete "{name}" tag from all proposals?</p>

              <Button
                type="primary"
                className="large-btn secondary-btn"
                onClick={() => deleteTagFromProposal(_id)}>
                Delete Permanently
              </Button>
            </div>
          }>
          <DeleteIcon className="delete-icon" />
        </Popover>
      </div>
      {error && <div className="error-mgs">An identical tag already exists.</div>}
    </div>
  );
};
