const updateSelection = ({ id, dispatch, getSelectionRange, getSelectedBlockElement }) => {
  const selectionRange = getSelectionRange();
  let selectedBlock;
  if (selectionRange) {
    selectedBlock = getSelectedBlockElement(selectionRange, id);
  }

  // if title or subtitle  and selectedBlock is null then return
  if (id.endsWith('_title') && !selectedBlock) {
    return;
  }

  dispatch({ type: 'setSelectedBlock', selectedBlock });
  dispatch({ type: 'setSelectionRange', selectionRange });
};

export default updateSelection;
