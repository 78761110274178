import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.25 13.75H3.47917L10.4792 6.75004L9.25 5.52087L2.25 12.5209V13.75ZM14.1875 5.54171L10.4583 1.81254L11.4792 0.791707C11.8264 0.444485 12.2396 0.270874 12.7188 0.270874C13.1979 0.270874 13.6111 0.444485 13.9583 0.791707L15.2083 2.04171C15.5417 2.37504 15.7083 2.78824 15.7083 3.28129C15.7083 3.77435 15.5417 4.18754 15.2083 4.52087L14.1875 5.54171ZM12.9583 6.77087L4.22917 15.5H0.5V11.7709L9.22917 3.04171L12.9583 6.77087ZM9.875 6.14587L9.25 5.52087L10.4792 6.75004L9.875 6.14587Z"
      fill="#05034D"
    />
  </svg>
);

const LinkEditIcon = (props) => <Icon component={svg} {...props} />;

export default LinkEditIcon;
