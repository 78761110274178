import React, { useState, useRef } from 'react';
import { Divider, Menu, Dropdown, Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import { ALIGNMENT_STYLES, BLOCK_TYPES, LINE_STYLES } from 'pages/Proposal/constants/constants';

import DropdownIcon from 'components/Icons/DropdownIcon';
import LinkIcon from 'components/Icons/LinkIcon';
import LineSpacingIcon from 'components/Icons/LineSpacingIcon';
import LeftIntentIcon from 'components/Icons/LeftIntentIcon';
import RightIntentIcon from 'components/Icons/RightIntentIcon';
import ClearFormattingIcon from 'components/Icons/ClearFormattingIcon';
import ListIcon from 'components/Icons/ListIcon';
import QuotesIcon from 'components/Icons/QuotesIcon';
import SplitSectionIcon from 'components/Icons/SplitSectionIcon';
import BoldIcon from 'components/Icons/BoldIcon';
import ItalicIcon from 'components/Icons/ItalicIcon';
import UnderlineIcon from 'components/Icons/UnderlineIcon';
import StrikeThroughIcon from 'components/Icons/StrikeThroughIcon';
import MenuSmallIcon from 'components/Icons/MenuSmallIcon';
import OverflowDropDownList from 'components/OverflowDropDownList/OverflowDropDownList';
import InputNumber from 'components/InputNumber';
import DraftContext from 'context/DraftContext';
import FontTools from './FontTools';

const ExternalToolbar = ({
  language,
  bodyFont,
  editorState,
  onToggle,
  onToggleBlockType,
  onToggleCustomBockStyle,
  onToggleFontInlineStyle,
  onClearFormatting,
  onSplitSection,
  dispatch,
  selectionActive,
  user,
  displayColorPicker,
  colorPicker,
}) => {
  const inputNumberRef = useRef(null);
  const [hoveredElement, setHoveredElement] = useState('');
  const [lineHeightDropDownVisible, setLineHeightDropDownVisible] = useState(false);
  const [lineHeightValue, setLineHeightValue] = useState(0);

  const selection = editorState.getSelection();
  const block = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());
  const blockType = (block && block.getType()) || '';
  const blockData = (block && block.getData()) || '';
  const blockClassName = blockData && blockData.get('className');
  const currentStyle = block ? editorState?.getCurrentInlineStyle() : '';

  const color = currentStyle
    ? currentStyle?.filter((value) => value?.toString()?.startsWith('rgba'))?.last() || '#4a4a4a'
    : '#4a4a4a';

  // selected text's background color [starts with bg-rgba()]
  let backgroundColor = currentStyle
    ? currentStyle?.filter((value) => value?.toString()?.startsWith('bg-'))?.last() ||
      'rgba(255,255,255,1)'
    : 'rgba(255,255,255,1)';
  if (backgroundColor?.startsWith('bg-')) {
    backgroundColor = backgroundColor.substring(3);
  }
  // REMARK
  // if the selected range contains red and blue text it will show the color that comes first
  // google doc dont show  any color in this case

  const toggleHover = (item) => setHoveredElement(item);

  const menuLineSpacingList = () => {
    const spacingSelectedKeys = [];
    LINE_STYLES.forEach((lineStyle) => {
      if (blockType.match(lineStyle.style)) spacingSelectedKeys.push(lineStyle.style);
    });

    return (
      <Menu
        onMouseLeave={() => {
          setLineHeightDropDownVisible(false);
          dispatch({ type: 'setLineHeightInput', lineHeightInput: false });
        }}
        selectedKeys={spacingSelectedKeys}
        className="rich-editor-intent-list-option">
        {LINE_STYLES.map((type) => (
          <Menu.Item
            key={type.style}
            onMouseDown={(e) => {
              e.preventDefault();
              onToggleCustomBockStyle({
                blockType: type.style,
                type: 'height',
              });
            }}>
            {type.text}
          </Menu.Item>
        ))}
        <Menu.Item>
          <Divider type="vertical" />
        </Menu.Item>

        <Menu.Item key={'custom_line_height'}>
          <InputNumber
            value={lineHeightValue}
            type="number"
            placeholder={1.0}
            min={0}
            max={18}
            step={0.25}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (inputNumberRef.current) {
                inputNumberRef.current.focus();
              }
            }}
            ref={inputNumberRef}
            onChange={(e) => {
              let value = parseFloat(e.target.value);
              let lineHeight = value.toFixed(1);
              if (!isNaN(value)) {
                value = Math.min(Math.max(value, 0), 18);
              }
              if (lineHeight) {
                lineHeight = lineHeight.replace(/\./, '-');
                console.log(lineHeight);
                setLineHeightValue(value);
                onToggleCustomBockStyle({
                  blockType: `line-height__${lineHeight}`,
                  type: 'height',
                });
              }
            }}
            onHandleChange={(value) => {
              let lineHeight = value.toFixed(1);
              if (lineHeight) {
                lineHeight = lineHeight.replace(/\./, '-');
                console.log(lineHeight);
                setLineHeightValue(value);
                onToggleCustomBockStyle({
                  blockType: `line-height__${lineHeight}`,
                  type: 'height',
                });
              }
            }}
          />
        </Menu.Item>
      </Menu>
    );
  };

  const SPACING_STYLES = [
    {
      style: 'line-height',
      text: 'Line height',
      icon: (
        <Dropdown
          onClick={() => setLineHeightDropDownVisible(!lineHeightDropDownVisible)}
          visible={lineHeightDropDownVisible}
          overlay={menuLineSpacingList}
          trigger={['click']}
          className="rich-editor-list">
          <Tooltip placement="top" title="Line height">
            <Button
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch({ type: 'setLineHeightInput', lineHeightInput: true });
              }}
              className={blockType && blockType.match(/line-height/) && 'toolbar-icon-active'}>
              <LineSpacingIcon />
              <DropdownIcon className="rich-editor-dropdown-icon" />
            </Button>
          </Tooltip>
        </Dropdown>
      ),
    },
    {
      style: 'left-intent',
      icon: (
        <Tooltip placement="top" title="Left indent">
          <LeftIntentIcon />
        </Tooltip>
      ),
    },
    {
      style: 'right-intent',
      icon: (
        <Tooltip placement="top" title="Right indent">
          <RightIntentIcon />
        </Tooltip>
      ),
    },
  ];

  const menuList = (
    <Menu selectedKeys={[blockClassName || blockType]} className="rich-editor-list-option">
      {BLOCK_TYPES.map((type) => (
        <Menu.Item
          key={type.style}
          icon={type.icon}
          onMouseDown={(e) => {
            e.preventDefault();
            onToggleBlockType(type.style, type.custom, null, blockClassName || blockType);
          }}
        />
      ))}
    </Menu>
  );

  const INLINE_STYLES = [
    { style: 'BOLD', text: 'Bold', icon: <BoldIcon /> },
    { style: 'ITALIC', text: 'Italic', icon: <ItalicIcon /> },
    { style: 'UNDERLINE', text: 'Underline', icon: <UnderlineIcon /> },
    {
      style: 'STRIKETHROUGH',
      text: 'Strikethrough',
      icon: <StrikeThroughIcon />,
    },
    {
      style: 'blockquote',
      text: 'Quotes',
      icon: <QuotesIcon />,
    },
    {
      style: 'COLOR',
      text: 'Text color',
      icon: (
        <div className="color-block">
          <div style={{ backgroundColor: color }} />
        </div>
      ),
    },
    {
      style: 'BACKGROUND',
      text: 'Background color',
      icon: (
        <div className="color-block">
          <div style={{ backgroundColor: backgroundColor }} />
        </div>
      ),
    },
  ];

  let right;
  let left;
  const directionBlockType = blockType?.match('editor-direction');

  if (directionBlockType && blockType.match(/ltr/)) left = true;
  else if (directionBlockType && blockType.match(/rtl/)) right = true;

  return (
    <DraftContext.Consumer>
      {({ collapse, collapseContentTable }) => (
        <OverflowDropDownList
          breakPoints={
            collapseContentTable
              ? [1500, 1400, 970, 850, 700, 600, 200]
              : [1800, 1700, 1600, 1000, 850, 800, 600, 200]
          }
          dropdownClassName="rich-editor-toolbar-overflow-dropdown"
          dropdownOverlayClassName="rich-editor-toolbar-overflow-dropdown-overlay"
          dropdownDynamic={false}
          dropdownPlacement="bottomLeft"
          dropdownIcon={<MenuSmallIcon className="rich-editor-toolbar-overflow-dropdown-trigger" />}
          dropdownOverFlowLimit={!collapse && !collapseContentTable ? 5 : 0}
          listItems={[
            <FontTools
              bodyFont={bodyFont}
              language={language?.toLowerCase()}
              editorState={editorState}
              onToggleFontInlineStyle={onToggleFontInlineStyle}
              user={user}
            />,
            <>
              {INLINE_STYLES.map((type) => (
                <Tooltip key={type.style} placement="top" title={type.text}>
                  <a
                    className={`rich-editor-toolbar-icon ${type.style} ${
                      (currentStyle && currentStyle?.has(type.style)) ||
                      blockType.search(type.style) > -1 ||
                      hoveredElement === type.style
                        ? 'true'
                        : ''
                    }`}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      type.style === 'blockquote'
                        ? onToggleBlockType('blockquote')
                        : onToggle({ inlineStyle: type.style });
                    }}
                    onMouseEnter={() => toggleHover(type.style)}
                    onMouseLeave={() => toggleHover('')}>
                    {type.icon}
                  </a>
                </Tooltip>
              ))}

              <Divider type="vertical">{displayColorPicker && colorPicker}</Divider>
            </>,

            <>
              <Tooltip placement="top" title="Insert Link">
                <a
                  className={`rich-editor-toolbar-icon ${
                    (currentStyle && currentStyle.has('link')) || hoveredElement === 'link'
                  }`}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    onToggle({
                      withoutText: selection.isCollapsed() ? true : false,
                      inlineStyle: 'LINK',
                    });
                  }}
                  onMouseEnter={() => toggleHover('link')}
                  onMouseLeave={() => toggleHover('')}>
                  <LinkIcon fill="#05034d" />
                </a>
              </Tooltip>
              <Tooltip placement="top" title="List">
                <Dropdown overlay={menuList} trigger={['click']} className="rich-editor-list">
                  <Button
                    className={
                      (blockType && blockType.match(/list-item/)) || hoveredElement === 'list-item'
                        ? 'toolbar-icon-active'
                        : ''
                    }
                    onMouseDown={(e) => e.preventDefault()}
                    onMouseEnter={() => toggleHover('list-item')}
                    onMouseLeave={() => toggleHover('')}>
                    <ListIcon />
                    <DropdownIcon className="rich-editor-dropdown-icon" />
                  </Button>
                </Dropdown>
              </Tooltip>
              <Divider type="vertical" />
            </>,

            <>
              {ALIGNMENT_STYLES.map((style) => (
                <Tooltip key={style.style} placement="top" title={style.text}>
                  <a
                    className={`rich-editor-toolbar-icon ${
                      hoveredElement === style.style ||
                      (!!blockType && blockType.match(style.style))
                        ? 'true'
                        : ''
                    } ${
                      left
                        ? 'editor-toolbar-direction-left'
                        : right
                        ? 'editor-toolbar-direction-right'
                        : ''
                    }`}
                    onMouseEnter={() => toggleHover(style.style)}
                    onMouseLeave={() => toggleHover('')}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      onToggleCustomBockStyle({
                        blockType: style.style,
                        type: style.type,
                      });
                    }}>
                    {style.type === 'editor-direction'
                      ? React.cloneElement(style.icon)
                      : style.icon}
                  </a>
                </Tooltip>
              ))}
              <Divider type="vertical" />
            </>,

            <>
              {SPACING_STYLES.map((style) => (
                <a
                  key={style.style}
                  className={`rich-editor-toolbar-icon ${
                    hoveredElement === style.style ||
                    (style.style === 'right-intent' && blockType.search('intent-left-') > -1) ||
                    (!!blockType && blockType.match(style.style))
                      ? 'true'
                      : ''
                  }`}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    if (style.style !== 'line-height')
                      onToggleCustomBockStyle({
                        blockType: style.style,
                        type: 'intent',
                      });
                  }}
                  onMouseEnter={() => toggleHover(style.style)}
                  onMouseLeave={() => toggleHover('')}>
                  {style.icon}
                </a>
              ))}
              <Divider type="vertical" />
            </>,

            <>
              <Tooltip placement="top" title="Clear formatting">
                <a
                  className={`rich-editor-toolbar-icon rich-editor-toolbar-clear  ${
                    hoveredElement === 'clear'
                  }`}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    onClearFormatting();
                  }}
                  onMouseEnter={() => toggleHover('clear')}
                  onMouseLeave={() => toggleHover('')}>
                  <ClearFormattingIcon fill="#05034d" />
                </a>
              </Tooltip>
              <Divider type="vertical" />
            </>,

            <Tooltip placement="top" title="Split Section">
              <a
                className={`rich-editor-toolbar-icon rich-editor-toolbar-clear  ${
                  hoveredElement === 'split'
                }
                ${selectionActive && 'disabled'}`}
                onMouseDown={(e) => {
                  e.preventDefault();
                  onSplitSection();
                }}
                onMouseEnter={() => toggleHover('split')}
                onMouseLeave={() => toggleHover('')}
                disabled={selectionActive}>
                <SplitSectionIcon fill="#05034d" />
              </a>
            </Tooltip>,
          ]}
        />
      )}
    </DraftContext.Consumer>
  );
};

ExternalToolbar.defaultProps = {
  bodyFont: '',
};

ExternalToolbar.propTypes = {
  language: PropTypes.string.isRequired,
  bodyFont: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  editorState: PropTypes.instanceOf(Object).isRequired,
  onToggle: PropTypes.func.isRequired,
  onToggleBlockType: PropTypes.func.isRequired,
  onToggleFontInlineStyle: PropTypes.func.isRequired,
  onToggleCustomBockStyle: PropTypes.func.isRequired,
  onClearFormatting: PropTypes.func.isRequired,
  onSplitSection: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default ExternalToolbar;
